import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import downLoad from '../assets/imgs/downLoad.png';
import websiteBanner from '../assets/imgs/bannerBg.png';
import phoneLinePc from '../assets/imgs/phoneLinePc.png';
import phoneLine from '../assets/imgs/phoneLine.png';
class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    // fetch('https://www.WePeso.com/api/download/page/config?hash=1',{
    fetch( window.location.origin + '/api/download/page/config?hash=1',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl, andro } = this.state;
    // // const appUrl = 'https://okpeso.onelink.me/KfYS/ADist';
    // const appUrl = '1212121';
    // console.log('----appUrl----', appUrl)
    console.log(isMobile,'isMobile');
    return (
      <Element id="home" component="section" className={`${className}-wrapper page`} onChange={navToShadow}>
        <div className={className}>
          {isMobile && <img src={websiteBanner} className={`${className}-bg-img`} alt="banner" />}
          <div className={`${className}-bg-word-wrapper`}>
            <div className={`${className}-word-main`}>
              <div className={`${className}-word-left`}>
                <p className={`${className}-word-left-title`}>{'¡Le Ayudamos A Obtener Un Préstamo Más Fácilmente!'}</p>
                <p className={`${className}-word-left-subtitle`}>{'En 4 Pasos Sencillos, Obtenga Su Dinero En Un Plazo De 2 A 24 Horas.'}</p>
                <p className={`${className}-word-left-desc`}>{'Registrarse • Enviar Información • Solicitar • Retirarse'}</p>
                {
                isMobile ? 
                <div className={`${className}-word-img-wrapperMobile`}>
                <img src={phoneLine} alt="img" />
              </div> : 
              <div className={`${className}-word-img-wrapper`}>
                <img src={phoneLinePc} alt="img" />
              </div>
              }
                <div className={`${className}-word-box`}>
                <a target="_blank" rel="noopener noreferrer" href={andro} key="a" className={`${className}-word-download`}>
                  <button className="banner-btn"><a  style={{color: '#FFFFFF'}} target="_blank" href={appUrl} key="a" rel="noopener noreferrer"><img className="banner-img" src={downLoad} alt='downLoad'/> Descargar YaCredito</a></button>
                  {/* <img className={`${className}-ims`} src={downloadImg} alt="img" /> */}
                </a>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {/* <div className={`${className}-md-img-wrapper`}>
          <img src={loanImg} alt="img" />
        </div> */}
      </Element>
    );
  }
}

export default Banner;
