
import onOne from '../assets/imgs/onOne.png';
import noteTwo from '../assets/imgs/noteTwo.png';
import noteThree from '../assets/imgs/noteThree.png';
import noteFour from '../assets/imgs/noteFour.png';
import planOne from '../assets/imgs/planOne.png';
import planTwo from '../assets/imgs/planTwo.png';
import planThree from '../assets/imgs/planThree.png';

export const page1 = [
  {
    img: onOne,
    icon: '#iconzu5',
    title: 'MÁS RÁPIDO',
    description: '1 DNI, 1 número de móvil,el préstamo emitido en 2 a 24 horas',
  },
  {
    img: noteTwo,
    icon: '#iconzu2',
    title: 'MÁS SEGURO',
    description: 'Información personal cifrada para garantizar la seguridad, sólo utilizada para a revisión y certificación',
  },
  {
    img: noteThree,
    icon: '#iconzu4',
    title: 'MÁS FÁCIL',
    description: 'Múltiples fuentes,tan fácil como comprar una cola',
  },
  {
    img: noteFour,
    icon: '#iconzu3',
    title: 'MÁS COMO UN AMIGO',
    description: 'Un equipo fuerte siempre está listo para servirle cuando encuentre dificultades',
  },
];

export const page3 = [
  {
    img: planOne,
    title: '¿Qué cualificaciones se requieren？',
    descriptions: [
      '1) Un número de teléfono en uso',
      '2) Identificación gubernamental válida',
      '3) Ingresos estables',
      '4) Edad entre 18 y 49 años'
    ],
  },
  {
    img: planThree,
    title: '¿Cómo solicitar un préstamo?',
    descriptions: [
      '1) Instale YaCredito y regístrese con su propio número de teléfono.',
      '2) Rellene el formulario de solicitud de préstamo en pesos.',
      '3) Espere la aprobación del préstamo en efectivo.',
      '4) Reclame su préstamo personal.'
    ],
  },
  {
    img: planTwo,
    title: '¿Cómo se hace el reembolso?',
    descriptions: [
      '1) Banca móvil, banca web pago en línea - fácil y rápido',
      '2) Agentes, agentes para el reembolso fuera de línea - proceso simple',
    ]
  },
];
